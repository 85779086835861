import { Actions as AppActions } from '../actions/app';
import {
  Actions as StreamApiActions,
  Channel,
  subscribeToDeviceStatus,
} from '../actions/streamApi';
import { Dispatch } from 'redux';

const API_BASE_URL = process.env.REACT_APP_STREAM_SERVICE_URL;

let socket: WebSocket = {} as WebSocket;
let keepAliveSocket;
let accessTokenClosure;

const streamApiMiddleware = (store: { getState; dispatch: Dispatch }) => (
  next: (action: any) => any
) => (action: any) => {
  if (
    action.type === AppActions.ACCESS_TOKEN_UPDATED ||
    action.type === AppActions.RESET_SOCKET_CONNECTION
  ) {
    const { accessToken } = action;

    accessTokenClosure = accessToken || accessTokenClosure;

    socket = new WebSocket(`${API_BASE_URL}`, [
      'accessToken',
      accessTokenClosure,
      'keepAlive',
    ]);

    if (!keepAliveSocket) {
      keepAliveSocket = setInterval(() => {
        if (socket.readyState !== 1) {
          socket.close();
          store.dispatch({
            type: AppActions.RESET_SOCKET_CONNECTION,
            accessToken: store.getState().app.accessToken,
          });
        }
      }, 5000);
    }

    socket.onopen = () => {
      store.dispatch({
        type: StreamApiActions.CONNECTED,
      });
      const { subscriptions } = store.getState();
      subscriptions.subscriptions.forEach(s => {
        const { vendor, uuid, id } = s;
        store.dispatch(subscribeToDeviceStatus(vendor, uuid, id));
      });
    };

    socket.onerror = (error: any) => {
      store.dispatch({
        type: StreamApiActions.ERROR,
        error,
      });
    };

    socket.onclose = (event: any) => {
      store.dispatch({
        type: StreamApiActions.CLOSED,
        reason: event.reason,
      });
    };

    socket.onmessage = (message: any) => {
      const data = JSON.parse(message.data);

      switch (data.channel) {
        case Channel.DEVICE_STATUS:
          store.dispatch({
            type: StreamApiActions.RECEIVED_DEVICE_STATUS,
            data: data.data,
          });
          break;

        case Channel.DEVICE_INSTALL_LIST:
          store.dispatch({
            type: StreamApiActions.RECEIVED_DEVICE_INSTALL_LIST,
            data: data.data,
          });
          break;

        case Channel.SENSOR_READINGS:
          store.dispatch({
            type: StreamApiActions.RECEIVED_DEVICE_READING,
            data: data.data,
          });
          break;

        case Channel.ENERGY_SENSOR_READING:
          const locale = store.getState().app.locale;
          store.dispatch({
            type: StreamApiActions.RECEIVED_ENERGY_PRO_READING,
            data: data.data,
            locale,
          });
          break;

        case Channel.PROPOSED_READING:
          store.dispatch({
            type: StreamApiActions.RECEIVED_PROPOSED_READING,
            data: data.data,
          });
          break;

        case Channel.REPORTS_STATUS:
          store.dispatch({
            type: StreamApiActions.RECEIVED_REPORT_STATUS,
            payload: data.data,
          });
          break;

        case Channel.PING:
          socket.send(JSON.stringify({ type: 'PONG' }));
          break;

        case Channel.FILE_INFO:
          store.dispatch({
            type: StreamApiActions.RECEIVED_FILE_INFO,
            data: data.data,
          });
          break;

        default:
          break;
      }
    };
  }

  if (action.type === StreamApiActions.SEND_MESSAGE) {
    if (socket.readyState === 1) {
      socket.send(JSON.stringify(action.data));
    }
  }

  next(action);
};

export default streamApiMiddleware;
