import { Button, Table } from '@energybox/react-ui-library/dist/components';
import { SensorsWhitelist } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { MdExitToApp } from 'react-icons/md';
import { ScannedSensor } from '../../reducers/scannedSensors';
import { DeviceInstallInfo } from '../../reducers/subscribedDeviceList';

type Props = {
  list?: ScannedSensor[];
  sensorsWhitelist?: SensorsWhitelist['uuids'];
  onSensorAdd: (sensorUuid: string) => void;
  isLoading: boolean;
  sensorsNearbyList?: DeviceInstallInfo[];
  isNetworkGroupPage?: boolean;
};
const SensorsNearbyTable = ({
  list = [],
  onSensorAdd,
  sensorsWhitelist = [],
  isLoading,
  isNetworkGroupPage = false,
  sensorsNearbyList = [],
}: Props) => {
  const columns = [
    {
      header: 'Sensor Uuid',
      width: '80%',
      cellContent: ({ uuid }: DeviceInstallInfo | ScannedSensor) => <span> {uuid}</span>,
    },
    ...(isNetworkGroupPage
      ? []
      : [
          {
            header: 'Actions',
            cellContent: ({ uuid }: DeviceInstallInfo | ScannedSensor) => (
              <Button
                variant="text"
                disabled={sensorsWhitelist.indexOf(uuid) !== -1 || isLoading}
                onClick={() => onSensorAdd(uuid)}
              >
                {' '}
                Add to Whitelist <MdExitToApp size={16} />
              </Button>
            ),
          },
        ]),
  ];

  const tableData = isNetworkGroupPage ? sensorsNearbyList : list;

  return (
    <Table
      columns={columns}
      data={tableData}
    />
  );
};

export default SensorsNearbyTable;
