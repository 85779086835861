import {
  GenericErrors,
  ResourceType,
  Sensor,
  SensorType,
  SensorVendors,
  IdListMapping,
  LoadingById,
} from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  mapArrayToObject,
  mapValues,
  values,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';

import { Actions } from '../actions/sensors';
import { Actions as StreamActions } from '../actions/streamApi';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import {
  checkIfReadingIsMoreRecent,
  normalizeSubscribedSensorReading,
} from '../utils/devices';
import { formValidationErrors } from '../utils/formValidation';
import { equipmentsFromApiResponse } from './equipment';
import { spacesFromApiResponse } from './spaces';

export interface EditableFields {
  title: string;
  description: string;
  uuid: string;
  vendor: SensorVendors;
  resourceId: number;
}

const editableFields = (sensor: object) =>
  R.pick(['title', 'description', 'uuid', 'vendor', 'resourceId'], sensor);

export type EditSensor = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
};

type NewSensorFields = {
  title?: string;
  description?: string;
  uuid?: string;
  vendor?: string;
  resourceId?: number;
};

const newSensorFields: NewSensorFields = {
  title: '',
  description: '',
  uuid: '',
  vendor: 'energybox',
  resourceId: -1,
};

type NewSensor = {
  preselectedFields?: {};
  fields: NewSensorFields;
  isLoading: boolean;
  isChanged: boolean;
  formErrors: {};
  formErrorsVisible: boolean;
  apiError: {};
};

const newSensor: NewSensor = {
  isLoading: false,
  isChanged: false,
  fields: newSensorFields,
  formErrors: formValidationErrors('sensor', newSensorFields),
  formErrorsVisible: false,
  apiError: {},
};

export type SensorsById = {
  [id: string]: Sensor;
};

export type SensorIdFromUuid = {
  [uuid: string]: number;
};

export type SensorByParentId = {
  [id: string]: number[];
};

export type sensorIdsByResourceId = {
  [id: number]: number[];
};

export interface Sensors {
  query: string;
  sensorIdFromUuid: SensorIdFromUuid;
  sensorIdsByParentId: SensorByParentId;
  sensorIdsByResourceId: sensorIdsByResourceId;
  sensorIdsBySiteId: IdListMapping;
  sensorIdsFilteredBySites: number[];
  sensorsById: SensorsById;
  sensorsByUuid: SensorsById;
  editById: EditById;
  showNewSensorModal: boolean;
  loadingStatusByAction: ActionsLoadingStatus;
  sensorReadingsById: SensorReadingsById;
  preloadedSensorsBySite: Sensor[];
}

export interface SensorTypeToReading {
  [SensorType.TEMPERATURE]?: SensorReading;
  [SensorType.HUMIDITY]?: SensorReading;
  [SensorType.BINARY]?: SensorReading;
}

export type SensorReadingsById = {
  [id: string]: SensorTypeToReading;
};

export interface SensorReadingFromAPI {
  processedAt: string;
  receivedAt: string;
  sensorId: string;
  sensor?: Sensor;
  timestamp: string;
  uuid: string;
  vendor: string;
  temperature?: number;
  humidity?: number;
  lux?: number;
  lux1Range?: number;
  lux2Range?: number;
  state?: [boolean];
}

export interface SensorReading {
  processedAt: string;
  receivedAt: string;
  sensorId: string;
  sensor?: Sensor;
  timestamp: string;
  uuid: string;
  vendor: string;
  temperature?: number;
  humidity?: number;
  lux?: number;
  lux1Range?: number;
  lux2Range?: number;
  binary?: boolean;
}

export type ActionsLoadingStatus = {
  [Actions.GET_SENSORS_LOADING]?: boolean;
  [Actions.GET_SENSORS_BY_SITE_ID_LOADING]?: LoadingById;
  [Actions.GET_PRELOADED_SENSORS_BY_SITE_LOADING]?: boolean;
};

export type EditById = {
  [id: string]: EditSensor;
};

export const sensorFromApiResponse = (data: any) => ({
  id: data.id,
  title: data.title,
  description: data.description || '',
  modelId: data.modelId || undefined,
  resourceId: data.resourceId,
  uuid: data.uuid,
  vendor: data.vendor,
  productId: data.productId || undefined,
  sensorStatus: data.sensorStatus || undefined,
  sensorInfo: data.sensorInfo || undefined,
  firmwareVersion: data.firmwareVersion || undefined,
  hardwareVersion: data.hardwareVersion || undefined,
  sensorOffset: data.sensorOffset || undefined,
  resource:
    data.resource._entity === 'Space'
      ? spacesFromApiResponse(data.resource)
      : equipmentsFromApiResponse(data.resource),
  parentResourceType: data.resource
    ? ResourceType[(data.resource._entity as string).toUpperCase()]
    : undefined,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  types: data.types,
  gatewayId: data.gatewayId || undefined,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  //Backend doesn't seem to return location
  // location: data.location,
});

export const initialState = {
  query: '',
  sensorsById: {},
  sensorsByUuid: {},
  sensorIdFromUuid: {},
  sensorIdsByParentId: {},
  sensorIdsBySiteId: {},
  sensorIdsFilteredBySites: [],
  showNewSensorModal: false,
  editById: {},
  sensorIdsByResourceId: {},
  loadingStatusByAction: {},
  sensorReadingsById: {},
  preloadedSensorsBySite: [],
};

export const sensorList = (state: Sensors, parentId: number) => {
  const { sensorsById, query, sensorIdsByParentId } = state;
  let sensors = values(sensorsById);

  if (parentId && parentId >= 0) {
    sensors = sensors.filter(
      sensor => (sensorIdsByParentId[parentId] || []).indexOf(sensor.id) >= 0
    );
  }

  if (query && query.length >= 3) {
    sensors = sensors.filter(sensor => hasSubstr(sensor.title, query));
  }

  return sensors;
};

export const sortedSensors = ({ sensorsById }: Sensors) =>
  values(sensorsById).sort((a, b) => a.title.localeCompare(b.title));

const sensors = (state: Sensors = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SENSORS_SUCCESS:
      return R.pipe(
        R.assoc(
          'sensorIdsByParentId',
          R.mergeRight(
            R.view(R.lensProp('sensorIdsByParentId'), state),
            R.reduceBy(
              (acc, { id }) => acc.concat(id),
              [],
              ({ resourceId }) => resourceId,
              action.data
            )
          )
        ),
        R.assoc(
          'sensorsById',
          R.mergeRight(
            R.view(R.lensProp('sensorsById'), state),
            mapArrayToObject(mapValues(action.data, sensorFromApiResponse))
          )
        ),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_SENSORS_LOADING],
          false
        )
      )(state);

    case Actions.GET_SENSORS_BY_SITE_ID_SUCCESS:
      const apiRes = action.data;
      const sensorIds = apiRes.map(({ id }) => id);
      return R.pipe(
        action.siteId
          ? R.assocPath(['sensorIdsBySiteId', [action.siteId]], sensorIds)
          : R.assocPath(['sensorIdsFilteredBySites'], sensorIds),
        R.assoc(
          'sensorIdsByParentId',
          R.mergeRight(
            R.view(R.lensProp('sensorIdsByParentId'), state),
            R.reduceBy(
              (acc, { id }) => acc.concat(id),
              [],
              ({ resourceId }) => resourceId,
              action.data
            )
          )
        ),
        R.assoc(
          'sensorsById',
          R.mergeRight(
            R.view(R.lensProp('sensorsById'), state),
            mapArrayToObject(mapValues(action.data, sensorFromApiResponse))
          )
        ),
        R.assocPath(
          [
            'loadingStatusByAction',
            Actions.GET_SENSORS_BY_SITE_ID_LOADING,
            [action.siteId],
          ],
          false
        )
      )(state);

    case Actions.GET_SENSORS_BY_RESOURCE_ID_LOADING:
    case Actions.GET_SENSORS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_SENSORS_LOADING],
        true,
        state
      );
    case Actions.GET_SENSORS_BY_SITE_ID_LOADING:
      return R.assocPath(
        [
          'loadingStatusByAction',
          Actions.GET_SENSORS_BY_SITE_ID_LOADING,
          [action.siteId],
        ],
        true,
        state
      );

    case Actions.UPDATE_SENSOR_OFFSET_SUCCESS:
    case Actions.GET_SENSOR_SUCCESS:
      let sensor = sensorFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(['sensorsById', action.data.id], sensor),
        R.assocPath(['sensorsByUuid', action.data.uuid], sensor),
        R.assocPath(['editById', action.data.id], {
          isLoading: false,
          formErrorsVisible: false,
          fields: editableFields(sensor),
        })
      )(state);

    case Actions.GET_SENSOR_ID_FROM_UUID_SUCCESS:
      return R.assocPath(['sensorIdFromUuid', action.id], action.data, state);

    case Actions.GET_SENSORS_BY_RESOURCE_ID_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['sensorIdsByResourceId', action.id],
          action.data.map(({ id }) => id)
        ),
        R.assoc(
          'sensorsById',
          R.mergeRight(
            R.view(R.lensProp('sensorsById'), state),
            mapArrayToObject(mapValues(action.data, sensorFromApiResponse))
          )
        ),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_SENSORS_LOADING],
          false
        )
      )(state);
    }

    case Actions.GET_PRELOADED_SENSORS_BY_SITE_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_PRELOADED_SENSORS_BY_SITE_LOADING],
        true,
        state
      );
    
    case Actions.GET_PRELOADED_SENSORS_BY_SITE_SUCCESS:
      return R.pipe(
        R.assoc('preloadedSensorsBySite', action.data),
        R.assocPath(
          ['loadingStatusByAction', Actions.GET_PRELOADED_SENSORS_BY_SITE_LOADING],
          false
        )
      )(state);
    
    case Actions.GET_PRELOADED_SENSORS_BY_SITE_ERROR:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_PRELOADED_SENSORS_BY_SITE_LOADING],
        false,
        state
      );

    case Actions.UPDATED_QUERY:
      return R.assoc('query', action.query, state);

    case Actions.TOGGLE_NEW_SENSOR_MODAL:
      if (action.preselect) {
        newSensor.preselectedFields = {
          uuid: action.preselect.uuid,
          resourceId: action.preselect.resourceId,
        };
        newSensor.fields = {
          ...newSensor.fields,
          ...(newSensor.preselectedFields as NewSensorFields),
        };
        Object.keys(newSensor.preselectedFields).forEach(key => {
          delete newSensor.formErrors[key];
        });
      }
      return R.pipe(
        R.assocPath(['editById', 'new'], newSensor),
        R.assoc('showNewSensorModal', action.value)
      )(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', action.id, 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', action.id, 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', action.id, 'fields'], updatedField),
        R.assocPath(['editById', action.id, 'isChanged'], true),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('sensor', updatedField)
        )
      )(state);

    case Actions.CREATE_SENSOR_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case Actions.CREATE_SENSOR_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['sensorsById', action.data.id],
          sensorFromApiResponse(action.data)
        ),
        // This is gross, gotta find a better way to do this in ramda
        R.assocPath(
          ['sensorIdsByResourceId', action.data.resourceId],
          (state['sensorIdsByResourceId'][action.data.resourceId] || []).concat(
            action.data.id
          )
        ),
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewSensorModal', false)
      )(state);
    }

    case Actions.CREATE_SENSOR_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case Actions.PATCH_SENSOR_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.PATCH_SENSOR_SUCCESS:
      const patchedSensor = sensorFromApiResponse(action.data);
      return R.pipe(
        R.assocPath(['sensorsById', action.id], patchedSensor),
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'isLoading'], false),
        R.assocPath(['editById', action.id, 'apiError'], {})
      )(state);

    case Actions.DELETE_SENSOR_ERROR:
    case Actions.PATCH_SENSOR_ERROR:
      return R.pipe(
        R.assocPath(['editById', action.id, 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', action.id, 'isLoading'], false)
      )(state);

    case Actions.RESET_EDIT_SENSOR:
      var fields = editableFields(R.path(['sensorsById', action.id], state));

      return R.pipe(
        R.assocPath(['editById', action.id, 'isChanged'], false),
        R.assocPath(['editById', action.id, 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', action.id, 'formErrors'],
          formValidationErrors('sensor', fields)
        )
      )(state);

    case Actions.DELETE_SENSOR_LOADING:
      return R.assocPath(['editById', action.id, 'isLoading'], true, state);

    case Actions.DELETE_SENSOR_SUCCESS:
      return R.pipe(
        R.dissocPath(['sensorsById', action.id]),
        R.dissocPath(['editById', action.id])
      )(state);

    case StreamActions.RECEIVED_DEVICE_READING:
      const normalizedPayload: SensorReading = normalizeSubscribedSensorReading(
        action.data
      );
      const { sensorId, temperature, humidity, binary } = normalizedPayload;
      let oldValue: SensorReading | undefined = undefined;
      let sensorType: SensorType | undefined = undefined;

      if (humidity || humidity === 0) {
        oldValue = R.pathOr(
          null,
          ['sensorReadingsById', sensorId, SensorType.HUMIDITY],
          state
        );
        sensorType = SensorType.HUMIDITY;
      } else if (temperature || temperature === 0) {
        oldValue = R.pathOr(
          null,
          ['sensorReadingsById', sensorId, SensorType.TEMPERATURE],
          state
        );
        sensorType = SensorType.TEMPERATURE;
      } else if (binary || binary === false) {
        oldValue = R.pathOr(null, [sensorId, SensorType.BINARY], state);
        sensorType = SensorType.BINARY;
      }

      if (
        oldValue === undefined ||
        !checkIfReadingIsMoreRecent(action.data, oldValue)
      ) {
        return state;
      } else {
        return R.assocPath(
          ['sensorReadingsById', sensorId, sensorType],
          normalizedPayload,
          state
        );
      }

    default:
      return state;
  }
};

export default sensors;
