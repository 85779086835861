import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { Equipment } from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  isDefined,
  shortenString,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  getEquipmentBySiteId,
  showNewEquipmentModal,
} from '../../actions/equipment';
import { ApplicationState } from '../../reducers';

interface OwnProps {
  onCreateEquipmentModalOpen?: () => void;
  onSelect: (equipmentId: number) => void;
  onLoad?: () => void;
  value?: number | null;
  siteId: number;
  disabled?: boolean;
  error?: boolean;
  noBottomLine?: boolean;
  customErrorText?: string;
  shortenStringLength?: number;
  isSuperHubPage?: boolean;
  preSelectedEquipment?: Equipment;
}

interface Props extends OwnProps {
  getEquipmentBySiteId: typeof getEquipmentBySiteId;
  showNewEquipmentModal: typeof showNewEquipmentModal;
  equipments: Equipment[];
}

interface State {
  search: string;
}

class SelectEquipment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { getEquipmentBySiteId, siteId } = this.props;
    if (siteId !== -1) {
      getEquipmentBySiteId(siteId);
    }
  }

  componentDidUpdate(prevProps) {
    const { getEquipmentBySiteId, siteId } = this.props;
    if (siteId !== prevProps.siteId && siteId !== -1) {
      getEquipmentBySiteId(siteId);
    }
  }

  filteredEquipment = (equipments: Equipment[]) => {
    const { search } = this.state;

    return equipments.filter((equipment: Equipment) => {
      if (search.length <= 2) return true;
      return hasSubstr(equipment.title, search);
    });
  };

  isSiteSelected = () => {
    if (this.props.siteId !== -1) {
      return true;
    }
    return false;
  };

  displayTitle = (title: string, shortenStringLength: number | undefined) => {
    if (isDefined(shortenStringLength)) {
      return shortenString(title, shortenStringLength);
    }
    return title;
  };

  render() {
    const {
      equipments,
      value,
      onSelect,
      disabled,
      error,
      customErrorText,
      shortenStringLength,
      noBottomLine = false,
      showNewEquipmentModal,
      onCreateEquipmentModalOpen,
      isSuperHubPage,
      preSelectedEquipment,
    } = this.props;

    if (isSuperHubPage && preSelectedEquipment) {
      return (
        <Select
          variant={'select'}
          disabled={true}
          title={this.displayTitle(preSelectedEquipment.title, shortenStringLength)}
          value={preSelectedEquipment.id}
          error={error}
          customErrorText={customErrorText}
          noBottomLine={noBottomLine}
        >
          <SelectItem
            isSelected={true}
            onSelect={() => {}}
          >
            {preSelectedEquipment.title}
          </SelectItem>
        </Select>
      );
    }

    if (!equipments) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;
      const selectedEquipment = equipments.find(
        equipment => equipment.id === value
      );
      const filteredEquipment = this.filteredEquipment(equipments);

      return (
        <Select
          variant={'select'}
          disabled={!this.isSiteSelected() || disabled}
          onClick={this.resetSearch.bind(this)}
          title={
            selectedEquipment
              ? this.displayTitle(selectedEquipment.title, shortenStringLength)
              : undefined
          }
          value={selectedEquipment?.id}
          error={error}
          customErrorText={customErrorText}
          noBottomLine={noBottomLine}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
            error={filteredEquipment.length === 0}
          />
          {onCreateEquipmentModalOpen && (
            <SelectItem
              onSelect={() => {
                onCreateEquipmentModalOpen();
                showNewEquipmentModal();
              }}
            >
              Add New Equipment
            </SelectItem>
          )}
          {filteredEquipment.map((equipment: Equipment) => (
            <SelectItem
              key={equipment.id}
              isSelected={equipment.id === selectedEquipment?.id}
              onSelect={() => onSelect(equipment.id)}
            >
              {equipment.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = (
  { equipment }: ApplicationState,
  { siteId }: OwnProps
) => ({
  equipments: (equipment.equipmentIdsBySiteId[siteId] || []).map(
    id => equipment.equipmentById[id]
  ),
});

const mapDispatchToProps = {
  getEquipmentBySiteId,
  showNewEquipmentModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectEquipment);
