import { EditThermostat } from '../types/Thermostat';
import { getGatewaysByNetworkGroupId, hideNewGatewayModal } from './gateways';

/**
 * Application is transitioning from venstarThermostat
 * which is specific to more specific thermostat
 */
const newApiBase = '/api/v1/thermostat';
const superHubApiBase = '/api/v1/superHub/admin-portal';

export enum Actions {
  CREATE_THERMOSTAT_DEVICE_LOADING = '@thermostat/CREATE_THERMOSTAT_DEVICE_LOADING',
  CREATE_THERMOSTAT_DEVICE_SUCCESS = '@thermostat/CREATE_THERMOSTAT_DEVICE_SUCCESS',
  CREATE_THERMOSTAT_DEVICE_ERROR = '@thermostat/CREATE_THERMOSTAT_DEVICE_ERROR',

  PATCH_THERMOSTAT_DEVICE_LOADING = '@thermostat/PATCH_THERMOSTAT_DEVICE_LOADING',
  PATCH_THERMOSTAT_DEVICE_SUCCESS = '@thermostat/PATCH_THERMOSTAT_DEVICE_SUCCESS',
  PATCH_THERMOSTAT_DEVICE_ERROR = '@thermostat/PATCH_THERMOSTAT_DEVICE_ERROR',

  DELETE_THERMOSTAT_DEVICE_LOADING = '@thermostat/DELETE_THERMOSTAT_DEVICE_LOADING',
  DELETE_THERMOSTAT_DEVICE_SUCCESS = '@thermostat/DELETE_THERMOSTAT_DEVICE_SUCCESS',
  DELETE_THERMOSTAT_DEVICE_ERROR = '@thermostat/DELETE_THERMOSTAT_DEVICE_ERROR',

  GET_THERMOSTAT_BY_ID_LOADING = '@thermostat/GET_THERMOSTAT_BY_ID_LOADING',
  GET_THERMOSTAT_BY_ID_SUCCESS = '@thermostat/GET_THERMOSTAT_BY_ID_SUCCESS',
  GET_THERMOSTAT_BY_ID_ERROR = '@thermostat/GET_THERMOSTAT_BY_ID_ERROR',

  GET_THERMOSTAT_DETAIL_BY_ID_LOADING = '@thermostat/GET_THERMOSTAT_DETAIL_BY_ID_LOADING',
  GET_THERMOSTAT_DETAIL_BY_ID_SUCCESS = '@thermostat/GET_THERMOSTAT_DETAIL_BY_ID_SUCCESS',
  GET_THERMOSTAT_DETAIL_BY_ID_ERROR = '@thermostat/GET_THERMOSTAT_DETAIL_BY_ID_ERROR',

  CLEAR_EDIT_THERMOSTAT_FORM_ERRORS = '@thermostat/CLEAR_EDIT_THERMOSTAT_FORM_ERRORS',

  GET_THERMOSTATS_BY_SITE_ID_LOADING = '@thermostat/GET_VENSTAR_THERMOSTATS_BY_SITE_ID_LOADING',
  GET_THERMOSTATS_BY_SITE_ID_SUCCESS = '@thermostat/GET_VENSTAR_THERMOSTATS_BY_SITE_ID_SUCCESS',
  GET_THERMOSTATS_BY_SITE_ID_ERROR = '@thermostat/GET_VENSTAR_THERMOSTATS_BY_SITE_ID_ERROR',

  TOGGLE_NEW_THERMOSTAT_MODAL = '@thermostat/TOGGLE_NEW_THERMOSTAT_MODAL',
}

export const create = (
  payload: EditThermostat,
  networkGroupId?: number | string
) => ({
  type: 'API_POST',
  path: `${newApiBase}`,
  payload,
  loading: Actions.CREATE_THERMOSTAT_DEVICE_LOADING,
  success: [
    {
      type: Actions.CREATE_THERMOSTAT_DEVICE_SUCCESS,
      siteId: payload.siteId,
    },
    getThermostatsBySiteId(payload.siteId),
    hideNewGatewayModal(),
    hideNewThermostatModal(),
    networkGroupId ? getGatewaysByNetworkGroupId(networkGroupId!) : '',
  ],
  error: Actions.CREATE_THERMOSTAT_DEVICE_ERROR,
});

export const patch = (id: string | number, payload: EditThermostat) => ({
  type: 'API_PATCH',
  path: `${newApiBase}/${id}`,
  payload,
  loading: Actions.PATCH_THERMOSTAT_DEVICE_LOADING,
  success: Actions.PATCH_THERMOSTAT_DEVICE_SUCCESS,
  error: Actions.PATCH_THERMOSTAT_DEVICE_ERROR,
});

export const destroy = (id: string | number) => ({
  type: 'API_DELETE',
  path: `${newApiBase}/${id}`,
  loading: {
    type: Actions.DELETE_THERMOSTAT_DEVICE_LOADING,
    id,
  },
  success: {
    type: Actions.DELETE_THERMOSTAT_DEVICE_SUCCESS,
    id,
  },
  error: {
    type: Actions.DELETE_THERMOSTAT_DEVICE_ERROR,
    id,
  },
});

export const getThermostatsBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `${newApiBase}/site/${siteId}`,
  loading: {
    type: Actions.GET_THERMOSTATS_BY_SITE_ID_LOADING,
    siteId,
  },
  success: [
    {
      type: Actions.GET_THERMOSTATS_BY_SITE_ID_SUCCESS,
      siteId,
    },
  ],
  error: {
    type: Actions.GET_THERMOSTATS_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const getThermostatById = (id: number | string) => ({
  type: 'API_GET',
  path: `${newApiBase}/${id}`,
  loading: {
    type: Actions.GET_THERMOSTAT_BY_ID_LOADING,
    id,
  },
  success: {
    type: Actions.GET_THERMOSTAT_BY_ID_SUCCESS,
    id,
  },
  error: {
    type: Actions.GET_THERMOSTAT_BY_ID_ERROR,
    id,
  },
});

export const getThermostatDetailsByUuid = (
  serialNumber: string,
  siteId: number | string,
  thermostatUuid: string
) => ({
  type: 'API_GET',
  path: `${superHubApiBase}/getThermostatDetailsByUuid?serialNumber=${serialNumber}&siteId=${siteId}&thermostatUuid=${thermostatUuid}`,
  loading: {
    type: Actions.GET_THERMOSTAT_DETAIL_BY_ID_LOADING,
    id: thermostatUuid,
  },
  success: {
    type: Actions.GET_THERMOSTAT_DETAIL_BY_ID_SUCCESS,
    id: thermostatUuid,
  },
  error: {
    type: Actions.GET_THERMOSTAT_DETAIL_BY_ID_ERROR,
    id: thermostatUuid,
  },
});

export const getThermostatDetailById = (id: number | string) => ({
  type: 'API_GET',
  path: `${superHubApiBase}/getThermostatDetailById?thermostatId=${id}`,
  loading: {
    type: Actions.GET_THERMOSTAT_DETAIL_BY_ID_LOADING,
    id,
  },
  success: {
    type: Actions.GET_THERMOSTAT_DETAIL_BY_ID_SUCCESS,
    id,
  },
  error: {
    type: Actions.GET_THERMOSTAT_DETAIL_BY_ID_ERROR,
    id,
  },
});

export const clearFormErrors = () => {
  return {
    type: Actions.CLEAR_EDIT_THERMOSTAT_FORM_ERRORS,
  };
};

export const showNewThermostatModal = () => {
  return {
    type: Actions.TOGGLE_NEW_THERMOSTAT_MODAL,
    value: true,
  };
};

export const hideNewThermostatModal = () => {
  return {
    type: Actions.TOGGLE_NEW_THERMOSTAT_MODAL,
    value: false,
  };
};
