import { Actions as StreamActions } from '../actions/streamApi';

  
  export type ThermostatDotCommonInfo = {
    paired: boolean;
    whitelisted: boolean;
    installed: boolean;
  };
  
  export type GatewayConnectedStatus = {
    connected: boolean;
    installed: boolean;
  };
  
  export type ThermostatStatus = {
    info: ThermostatDotCommonInfo;
    display_name: string;
    temperature: number;
    humidity: number;
    timestamp: number;
  };
  
  type DotReadingType = 'BINARY' | 'AMBIENT' | 'HUMIDITY' | 'TEMPERATURE';
  
  type BinaryAccessReading = {
    access_state: boolean;
    timestamp: number;
  };
  
  type AmbientHumidityReading = {
    temperature: number;
    humidity: number;
    timestamp: number;
  };
  
  type TemperatureSensorReading = {
    temperature: number;
    timestamp: number;
  };
  
  export type SiteControllerStatus = {
    status: GatewayConnectedStatus;
    port1Lux: number;
    port2Lux: number;
  };
  
  export type EnergyProStatus = {
    status: GatewayConnectedStatus;
  };
  
  export type UnifiedDeviceType = 'THERMOSTAT' | 'DOT' | 'SITE_CONTROLLER' | 'ENERGY_PRO';
  
  export type DeviceSpecificStatus = 
    | { 'com.energybox.ThermostatDeviceStatus': ThermostatStatus }
    | { 'com.energybox.DotStatus': DotStatus }
    | { 'com.energybox.SiteControllerStatus': SiteControllerStatus }
    | { 'com.energybox.EnergyProStatus': EnergyProStatus };
  
  export type DotReading = 
    | { 'com.energybox.BinaryAccessReading': BinaryAccessReading }
    | { 'com.energybox.AmbientHumidityReading': AmbientHumidityReading }
    | { 'com.energybox.TemperatureSensorReading': TemperatureSensorReading };
  
  export type DotStatus = {
    reading_types: DotReadingType[];
    status: ThermostatDotCommonInfo;
    reading: DotReading;
  };
  
  export type DeviceInstallInfo = {
    uuid: string;
    device_type: UnifiedDeviceType;
    version: string;
    device_specific_status: DeviceSpecificStatus;
  };
  
  export type DeviceInstallList = {
    uuid: string;
    devices: DeviceInstallInfo[];
  };
  
  export type DeviceList = {
    deviceList: DeviceInstallList;
  };
  
  const initialState: DeviceList = {
    deviceList: {
      uuid: '',
      devices: [],
    },
  };
  
  export function getDeviceStatus(device: DeviceInstallInfo): ThermostatDotCommonInfo | GatewayConnectedStatus {
    const status = device.device_specific_status;
    if ('com.energybox.ThermostatDeviceStatus' in status) return status['com.energybox.ThermostatDeviceStatus'].info;
    if ('com.energybox.DotStatus' in status) return status['com.energybox.DotStatus'].status;
    if ('com.energybox.SiteControllerStatus' in status) return status['com.energybox.SiteControllerStatus'].status;
    if ('com.energybox.EnergyProStatus' in status) return status['com.energybox.EnergyProStatus'].status;
    throw new Error('Unknown device type');
  }
  
  export function isThermostatDotInfo(status: ThermostatDotCommonInfo | GatewayConnectedStatus): status is ThermostatDotCommonInfo {
    return 'whitelisted' in status;
  }
  
  export function isGatewayConnectedStatus(status: ThermostatDotCommonInfo | GatewayConnectedStatus): status is GatewayConnectedStatus {
    return 'connected' in status;
  }
  
  export function isPaired(device: DeviceInstallInfo): boolean {
    const status = getDeviceStatus(device);
    return isThermostatDotInfo(status) && status.paired;
  }
  
  export function isInstalled(device: DeviceInstallInfo): boolean {
    return getDeviceStatus(device).installed;
  }
  
  const subscribedDeviceList = (
    state = initialState,
    action: any
  ): DeviceList => {
    if (action.type === StreamActions.RECEIVED_DEVICE_INSTALL_LIST) {
      return {
        ...state,
        deviceList: action.data,
      };
    } else {
      return state;
    }
  };
  
  export default subscribedDeviceList;