import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Checkbox, Loader, Portal } from '@energybox/react-ui-library/dist/components';
import SignalStrength from '../SignalStrength/SignalStrength';
import styles from './LookupWhitelistedButton.module.css';
import { formatUuid } from '../../utils/devices';
import { global } from '@energybox/react-ui-library/dist/utils';

type Device = {
  uuid: string;
  title: string;
  signalStrength?: number | null;
};

type Props = {
  dropdownTitle: string;
  devices: Device[];
  currentUuid: string | undefined;
  onDeviceClick: (uuid: string) => void;
  buttonLabel?: string;
  onButtonClick?: () => void;
  isLoading?: boolean;
  active?: boolean;
  showSignalStrength?: boolean;
  isSuperHubWhitelist?: boolean;
};

const LookupWhitelistedButton: React.FC<Props> = ({
  dropdownTitle,
  devices,
  currentUuid,
  onDeviceClick,
  buttonLabel,
  onButtonClick,
  isLoading,
  active = false,
  showSignalStrength = false,
  isSuperHubWhitelist = false,
}) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const handleClick = () => {
    if (onButtonClick) {
      onButtonClick();
    }
    setIsExpanded(!isExpanded);
  };

  const updateDropdownPosition = useCallback(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  }, []);

  useEffect(() => {
    if (isExpanded) {
      updateDropdownPosition();
      window.addEventListener('resize', updateDropdownPosition);
      window.addEventListener('scroll', updateDropdownPosition);
    }
    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
      window.removeEventListener('scroll', updateDropdownPosition);
    };
  }, [isExpanded, updateDropdownPosition]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

      return (
        <>
          <div ref={buttonRef}>
            <Button 
              variant="outlined" 
              onClick={handleClick} 
              disabled={!active}
              className={styles.lookupButton}
            >
              {buttonLabel || 'Lookup UUID'}
            </Button>
          </div>
          {isExpanded && (
            <Portal id="uuid-lookup">
              <div
                ref={dropdownRef}
                className={styles.lookupDropdown}
                style={{
                  top: `${dropdownPosition.top}px`,
                  left: `${dropdownPosition.left}px`,
                }}
              >
                <div className={styles.dropdownTitle}>{dropdownTitle}</div>
                <div className={styles.lookupList}>
                  {isLoading && <Loader size={18} className={styles.loader} />}
                  {!isLoading && devices.length === 0 && (
                    <div className={styles.noDevices}>No Sensors found</div>
                  )}
                  {!isLoading &&
                    devices.map(({ uuid, title, signalStrength }) => (
                      <div
                        key={uuid}
                        className={`${styles.lookupItem} ${
                          currentUuid === formatUuid(uuid) ? styles.selected : ''
                        }`}
                        onClick={() => {
                          onDeviceClick(uuid);
                          setIsExpanded(false);
                        }}
                      >
                        <Checkbox
                          checked={currentUuid === formatUuid(uuid)}
                          onChange={() => {}}
                        />
                        <span className={styles.deviceTitle}>
                          {isSuperHubWhitelist ? title : formatUuid(uuid)}
                        </span>
                        {showSignalStrength && (
                          <div className={styles.signalStrength}>
                            {signalStrength ? (
                              <SignalStrength value={signalStrength} />
                            ) : (
                              global.NOT_AVAILABLE
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </Portal>
          )}
        </>
      );
    };
    
    export default LookupWhitelistedButton;